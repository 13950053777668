export enum Tags {
    Gis = 'Gis',
    Tag = 'Tag',
    User = 'User',
    Info = 'Info',
    Asset = 'Asset',
    Model = 'Model',
    Owner = 'Owner',
    Credit = 'Credit',
    Marker = 'Marker',
    Object = 'Object',
    Status = 'Status',
    Sensor = 'Sensor',
    Message = 'Message',
    Profile = 'Profile',
    Document = 'Document',
    Workflow = 'Workflow',
    ACCObject = 'ACCObject',
    ACCStatus = 'ACCStatus',
    Atividade = 'Atividade',
    SuperUser = 'SuperUser',
    AssetProps = 'AssetProps',
    Discipline = 'Discipline',
    SensorData = 'SensorDatas',
    MultiPolygon = 'MultiPolygon',
}

export const allTags = Object.values(Tags);
