import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import { GenericPrivateRoute } from './GenericPrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';
import { FuncaoValues, UserDto, UsuarioF, UsuarioFuncao } from 'types';
import { CustomAlerts } from 'components/CustomComponents/Alerts';
import { getLayout } from './utils';
import { checkCan } from 'casl/check';
import { AcessAction, AcessItem } from 'types/enum/atividades';

// lazy load all the views
//pagina para features em construção
const NotBuild = React.lazy(/* istanbul ignore next */ () => import('pages/build'));

//pagina para error404
const Error404 = React.lazy(() => import('pages/error/Error404'));
//ativos
const Active = React.lazy(/* istanbul ignore next */ () => import('pages/Ativos/Active'));
const AtivoCreate = React.lazy(/* istanbul ignore next */ () => import('pages/Ativos/Create'));
const AtivoDetails = React.lazy(/* istanbul ignore next */ () => import('pages/Ativos/Detail'));
const AtivoEdit = React.lazy(/* istanbul ignore next */ () => import('pages/Ativos/Edit'));

//Modulo de Ia
const ListAsset = React.lazy(/* istanbul ignore next */ () => import('pages/Monitoramento/List'));
const IADetail = React.lazy(/* istanbul ignore next */ () => import('pages/Monitoramento/Detail'));
const SensorsLimits = React.lazy(/* istanbul ignore next */ () => import('pages/Sensores/SensorsLimits'));
const Reports = React.lazy(/* istanbul ignore next */ () => import('pages/Sensores/Reports'));

//Forge ACC
const ForgeACC = React.lazy(/* instanbul ignore next */ () => import('pages/ACCAccount'));
const CodeReceiver = React.lazy(/* instanbul ignore next */ () => import('pages/CodeReceiver'));
const ACCModels = React.lazy(/* instanbul ignore next */ () => import('pages/ACCModels'));
const ACCDocuments = React.lazy(/* instanbul ignore next */ () => import('pages/ACCDocuments'));

//Forge Bucket
const BucketDetails = React.lazy(/* istanbul ignore next */ () => import('pages/Bucket/Details'));

//Profile account
const MyProfile = React.lazy(/* istanbul ignore next */ () => import('pages/MyProfile'));

//usuarios
const User = React.lazy(/* istanbul ignore next */ () => import('pages/Usuarios/User'));
const UserEdit = React.lazy(/* istanbul ignore next */ () => import('pages/Usuarios/Edit'));
const UserCreate = React.lazy(/* istanbul ignore next */ () => import('pages/Usuarios/Create'));

//superusuarios
const SuperUserList = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/SuperUsers/List'));
const SuperUserCreate = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/SuperUsers/Create'));
const SuperUserEdit = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/SuperUsers/Edit'));

//Modelos3D
const Modelos3D = React.lazy(/* istanbul ignore next */ () => import('pages/Modelos3D'));
const ModelView = React.lazy(/* istanbul ignore next */ () => import('pages/Modelos3D/View'));
const AllModelView = React.lazy(/* istanbul ignore next */ () => import('pages/Modelos3D/ViewAll'));
//Sensores
const SensorData = React.lazy(/* istanbul ignore next */ () => import('pages/Sensores/Sensor'));
const Sensores = React.lazy(/* istanbul ignore next */ () => import('pages/Sensores/List'));

const SensorCreate = React.lazy(() => import('pages/Sensores/Create'));
const SensorEdit = React.lazy(() => import('pages/Sensores/Edit'));
// const SensorMultiCreate = React.lazy(() => import('pages/Sensores/Multi-Create'));

//disciplinas
const Disciplinas = React.lazy(/* istanbul ignore next */ () => import('pages/Disciplinas'));
//workflow
const WorkflowList = React.lazy(/* istanbul ignore next */ () => import('pages/Workflow/List'));
const QuadroWorkflow = React.lazy(/* istanbul ignore next */ () => import('pages/Workflow/Atividades/Quadro'));
const AtividadesLista = React.lazy(/* istanbul ignore next */ () => import('pages/Atividades'));
// auth
const Login = React.lazy(/* istanbul ignore next */ () => import('pages/account/Login'));
const Logout = React.lazy(/* istanbul ignore next */ () => import('pages/account/Logout'));
const Register = React.lazy(/* istanbul ignore next */ () => import('pages/account/Register'));
const Confirm = React.lazy(/* istanbul ignore next */ () => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(/* istanbul ignore next */ () => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(/* istanbul ignore next */ () => import('pages/account/LockScreen'));

const Login2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/Login2'));
const Logout2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/Logout2'));
const Register2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/Register2'));
const Confirm2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/ForgetPassword2'));
const LockScreen2 = React.lazy(/* istanbul ignore next */ () => import('pages/account/LockScreen2'));

//Documentos
const Documentos = React.lazy(/* istanbul ignore next */ () => import('pages/Documentos/index'));
const CriarDocumento = React.lazy(/* istanbul ignore next */ () => import('pages/Documentos/Criar/index'));
const EditarDocumento = React.lazy(/* istanbul ignore next */ () => import('pages/Documentos/Edit/index'));
// dashboard
const AnalyticsDashboard = React.lazy(/* istanbul ignore next */ () => import('pages/dashboard/Analytics'));
const EcommerceDashboard = React.lazy(/* istanbul ignore next */ () => import('pages/dashboard/Ecommerce'));
const ProjectDashboard = React.lazy(/* istanbul ignore next */ () => import('pages/dashboard/Project'));
const EWalletDashboard = React.lazy(/* istanbul ignore next */ () => import('pages/dashboard/E-Wallet'));

// apps
const CalendarApp = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Calendar'));
const ProjectList = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Projects/List'));
const ProjectDetail = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Projects/Gantt'));
const ProjectForm = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Chat/'));

// -crm
const CRMDashboard = React.lazy(/* istanbul ignore next */ () => import('pages/apps/CRM/Dashboard'));
const CRMProjects = React.lazy(/* istanbul ignore next */ () => import('pages/apps/CRM/Projects'));
const CRMManagement = React.lazy(/* istanbul ignore next */ () => import('pages/apps/CRM/Management'));
const CRMClients = React.lazy(/* istanbul ignore next */ () => import('pages/apps/CRM/Clients'));
const CRMOrderList = React.lazy(/* istanbul ignore next */ () => import('pages/apps/CRM/OrderList'));

// - ecommece pages
const EcommerceProducts = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(/* istanbul ignore next */ () => import('pages/apps/SocialFeed/'));

// - tasks
const TaskList = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Tasks/Details'));
const Kanban = React.lazy(/* istanbul ignore next */ () => import('pages/apps/Tasks/Board/'));
// - file
const FileManager = React.lazy(/* istanbul ignore next */ () => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(/* istanbul ignore next */ () => import('pages/profile'));
const Profile2 = React.lazy(/* istanbul ignore next */ () => import('pages/profile2'));
const ErrorPageNotFound = React.lazy(/* istanbul ignore next */ () => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(/* istanbul ignore next */ () => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(/* istanbul ignore next */ () => import('pages/error/ServerError'));

// activate
const ActivateSuccess = React.lazy(/* istanbul ignore next */() => import('pages/Activate/Success'))
const ActivateError = React.lazy(/* istanbul ignore next */() => import('pages/Activate/Error'))
// - other
const Invoice = React.lazy(/* istanbul ignore next */ () => import('pages/other/Invoice'));
const FAQ = React.lazy(/* istanbul ignore next */ () => import('pages/other/FAQ'));
const Pricing = React.lazy(/* istanbul ignore next */ () => import('pages/other/Pricing'));
const Maintenance = React.lazy(/* istanbul ignore next */ () => import('pages/other/Maintenance'));
const Starter = React.lazy(/* istanbul ignore next */ () => import('pages/other/Starter'));
const PreLoader = React.lazy(/* istanbul ignore next */ () => import('pages/other/PreLoader/'));
const Timeline = React.lazy(/* istanbul ignore next */ () => import('pages/other/Timeline'));

const Landing = React.lazy(/* istanbul ignore next */ () => import('pages/landing/'));

// uikit
const Accordions = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Accordions'));
const Alerts = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Alerts'));
const Avatars = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Avatars'));
const Badges = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Badges'));
const Breadcrumbs = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Breadcrumb'));
const Buttons = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Buttons'));
const Cards = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Cards'));
const Carousels = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Carousel'));
const Dropdowns = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/EmbedVideo'));
const Grid = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Grid'));
const ListGroups = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/ListGroups'));
const Modals = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Modals'));
const Notifications = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Notifications'));
const Offcanvases = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Offcanvas'));
const Placeholders = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Placeholders'));
const Paginations = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Paginations'));
const Popovers = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Popovers'));
const Progress = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Progress'));
const Ribbons = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Ribbons'));
const Spinners = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Spinners'));
const Tabs = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Tabs'));
const Tooltips = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Tooltips'));
const Typography = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Typography'));
const DragDrop = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/DragDrop'));
const RangeSliders = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/RangeSliders'));
const Ratings = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(/* istanbul ignore next */ () => import('pages/icons/Dripicons'));
const MDIIcons = React.lazy(/* istanbul ignore next */ () => import('pages/icons/MDIIcons'));
const Unicons = React.lazy(/* istanbul ignore next */ () => import('pages/icons/Unicons'));

// forms
const BasicForms = React.lazy(/* istanbul ignore next */ () => import('pages/forms/Basic'));
const FormAdvanced = React.lazy(/* istanbul ignore next */ () => import('pages/forms/Advanced'));
const FormValidation = React.lazy(/* istanbul ignore next */ () => import('pages/forms/Validation'));
const FormWizard = React.lazy(/* istanbul ignore next */ () => import('pages/forms/Wizard'));
const FileUpload = React.lazy(/* istanbul ignore next */ () => import('pages/forms/FileUpload'));
const Editors = React.lazy(/* istanbul ignore next */ () => import('pages/forms/Editors'));

// charts
const ApexChart = React.lazy(/* istanbul ignore next */ () => import('pages/charts/Apex'));
const ChartJs = React.lazy(/* istanbul ignore next */ () => import('pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(/* istanbul ignore next */ () => import('pages/tables/Basic'));
const AdvancedTables = React.lazy(/* istanbul ignore next */ () => import('pages/tables/Advanced'));

// widgets
const Widgets = React.lazy(/* istanbul ignore next */ () => import('pages/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(/* istanbul ignore next */ () => import('pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(/* istanbul ignore next */ () => import('pages/maps/VectorMaps'));

// superuser
const LoginSuper = React.lazy(/* istanbul ignore next */ () => import('pages/account/LoginSuper'));
const ClienteList = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/List'));
const ClienteCreate = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/Create'));
const ClienteEdit = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/Edit'));
const ClienteView = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/Options/Info'));
const ClienteOptions = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/Options'));
const ClienteMetricas = React.lazy(
    /* istanbul ignore next */ () => import('pages/SuperUsuario/Owners/Options/Metricas')
);
const CloudCredits = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/CloudCredits'));
const SystemPage = React.lazy(/* istanbul ignore next */ () => import('pages/SuperUsuario/System'));

//Gis
const GisAdd = React.lazy(/* istanbul ignore next */ () => import('pages/Gis/Add'));
const GisList = React.lazy(/* istanbul ignore next */ () => import('pages/Gis/List'));
const GisDetail = React.lazy(/* istanbul ignore next */ () => import('pages/Gis/Detail'));
/* istanbul ignore next */
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
    roles?: FuncaoValues[];
    super?: boolean;
    secondCheck?: (u: UserDto) => boolean;
};
/* istanbul ignore next */
const LoadComponent = ({ component: Component, roles, super: isSuper, secondCheck }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <CustomAlerts />
        {roles || isSuper !== undefined || secondCheck !== undefined ? (
            <GenericPrivateRoute
                roles={roles}
                component={Component}
                secondCheck={isSuper ? (u) => u.isSuperuser : secondCheck ? secondCheck : (u) => !u.isSuperuser}
                isSuper={isSuper}
            />
        ) : (
            <Component />
        )}
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    let Layout = getLayout(layout.layoutType);

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // rotas publicas
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'admin/login',
                    element: <LoadComponent component={LoginSuper} />,
                },
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        {
                            path: 'lock-screen',
                            element: (
                                <LoadComponent
                                    component={LockScreen}
                                    // roles={[UsuarioFuncao.COMUM, UsuarioFuncao.GLOBAL, UsuarioFuncao.ADMINISTRADOR]}
                                />
                            ),
                        },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'activate-success',
                    element: <LoadComponent component={ActivateSuccess} />
                },
                {
                    path: 'activate-error',
                    element: <LoadComponent component={ActivateError} />
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // rotas protegidas super usuario
            path: '/',
            element: (
                <GenericPrivateRoute
                    component={Layout}
                    secondCheck={/* istanbul ignore next */ (u) => u.isSuperuser}
                    isSuper={true}
                />
            ),
            children: [
                {
                    path: 'system',
                    element: <LoadComponent component={SystemPage} />,
                },
                {
                    path: 'forge',
                    element: <LoadComponent component={NotBuild} />,
                },
                {
                    path: 'cloud-credits',
                    element: <LoadComponent component={CloudCredits} />,
                },
                {
                    path: 'clientes',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={ClienteList} />,
                        },
                        {
                            path: 'criar',
                            element: <LoadComponent component={ClienteCreate} />,
                        },
                        {
                            path: ':ownerId',
                            children: [
                                {
                                    path: 'info',
                                    children: [
                                        {
                                            path: '',
                                            element: <LoadComponent component={ClienteView} />,
                                        },
                                        {
                                            path: 'metricas',
                                            element: <LoadComponent component={ClienteMetricas} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'editar',
                                    element: <LoadComponent component={ClienteEdit} />,
                                },
                                {
                                    path: 'visualizar',
                                    element: <LoadComponent component={ClienteOptions} />,
                                },
                                {
                                    path: 'usuarios',
                                    children: [
                                        {
                                            path: '',
                                            element: <LoadComponent component={User} />,
                                        },
                                        {
                                            path: 'criar',
                                            element: <LoadComponent component={UserCreate} />,
                                        },
                                        {
                                            path: ':id',
                                            children: [
                                                {
                                                    path: 'editar',
                                                    element: <LoadComponent component={UserEdit} />,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'ativos',
                                    children: [
                                        {
                                            path: '',
                                            element: <LoadComponent component={Active} />,
                                        },
                                        {
                                            path: 'criar',
                                            element: <LoadComponent component={AtivoCreate} />,
                                        },
                                        {
                                            path: ':id',
                                            children: [
                                                {
                                                    path: '',
                                                    element: <LoadComponent component={AtivoDetails} />,
                                                },
                                                {
                                                    path: 'editar',
                                                    element: <LoadComponent component={AtivoEdit} />,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: 'chamados',
                                    element: <LoadComponent component={NotBuild} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'superusuarios',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={SuperUserList} />,
                        },
                        {
                            path: 'criar',
                            element: <LoadComponent component={SuperUserCreate} />,
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: 'editar',
                                    element: <LoadComponent component={SuperUserEdit} />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            // rotas em comum entre super usuario e usuarios gerais
            path: '/',
            element: (
                <GenericPrivateRoute
                    secondCheck={
                        /* istanbul ignore next */ (u) =>
                            u.isSuperuser ||
                            [UsuarioFuncao.COMUM, UsuarioFuncao.GLOBAL, UsuarioFuncao.ADMINISTRADOR].includes(u.role)
                    }
                    component={Layout}
                />
            ),
            children: [
                {
                    path: 'profile',
                    element: <LoadComponent component={MyProfile} />,
                },
            ],
        },
        {
            // rotas protegidas usuarios gerais
            path: '/',
            element: (
                <GenericPrivateRoute
                    roles={[UsuarioFuncao.COMUM, UsuarioFuncao.GLOBAL, UsuarioFuncao.ADMINISTRADOR]}
                    component={Layout}
                    secondCheck={/* istanbul ignore next */ (u) => !u.isSuperuser}
                    isSuper={false}
                />
            ),
            children: [
                {
                    path: 'ativos',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Active} />,
                        },
                        {
                            path: 'criar',
                            element: (
                                <LoadComponent
                                    secondCheck={
                                        /* istanbul ignore next */ (u) =>
                                            checkCan(u, AcessAction.CREATE, AcessItem.ATIVO)
                                    }
                                    component={AtivoCreate}
                                />
                            ),
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={AtivoDetails} />,
                                },
                                {
                                    path: 'editar',
                                    element: (
                                        <LoadComponent
                                            secondCheck={
                                                /* istanbul ignore next */ (u) =>
                                                    checkCan(u, AcessAction.UPDATE, AcessItem.ATIVO)
                                            }
                                            component={AtivoEdit}
                                        />
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'administracao',
                    children: [
                        {
                            path: 'forge',
                            element: (
                                <LoadComponent
                                    secondCheck={
                                        /* istanbul ignore next */
                                        (u) => checkCan(u, AcessAction.READ, AcessItem.FORGE)
                                    }
                                    component={BucketDetails}
                                />
                            ),
                        },
                        {
                            path: 'modelos-acc',
                            element: (
                                <LoadComponent
                                    secondCheck={
                                        /* istanbul ignore next */
                                        (u) => checkCan(u, AcessAction.READ, AcessItem.FORGE)
                                    }
                                    component={ACCModels}
                                />
                            ),
                        },
                        {
                            path: 'acc',
                            element: <LoadComponent roles={[UsuarioFuncao.GLOBAL]} component={ForgeACC} />,
                        },
                        {
                            path: 'acc-docs',
                            element: <LoadComponent roles={[UsuarioFuncao.GLOBAL]} component={ACCDocuments} />,
                        },
                        {
                            path: 'code',
                            element: <LoadComponent roles={[UsuarioFuncao.GLOBAL]} component={CodeReceiver} />,
                        },
                        {
                            path: 'usuarios',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={User} />,
                                },
                                {
                                    path: 'criar',
                                    element: (
                                        <LoadComponent
                                            secondCheck={
                                                /* istanbul ignore next */ (u) =>
                                                    checkCan(u, AcessAction.CREATE, AcessItem.USUARIO)
                                            }
                                            component={UserCreate}
                                        />
                                    ),
                                },
                                {
                                    path: ':id',
                                    children: [
                                        {
                                            path: 'editar',
                                            element: (
                                                <LoadComponent
                                                    secondCheck={
                                                        /* istanbul ignore next */ (u) =>
                                                            checkCan(u, AcessAction.UPDATE, AcessItem.USUARIO)
                                                    }
                                                    component={UserEdit}
                                                />
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'gis',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={GisList} />,
                        },
                        {
                            path: ':assetId',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={GisDetail} />,
                                },
                                {
                                    path: 'add',
                                    element: <LoadComponent component={GisAdd} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'monitoramento',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={NotBuild} />,
                        },
                        {
                            path: ':assetId',
                            element: <LoadComponent component={NotBuild} />,
                        },
                        {
                            path: 'limites',
                            element: <LoadComponent component={SensorsLimits} />,
                        },
                        {
                            path: 'relatorios',
                            element: <LoadComponent component={Reports} />,
                        },
                    ],
                },
                {
                    path: 'atividades',
                    element: <LoadComponent component={AtividadesLista} />,
                },
                {
                    path: 'documentos',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Documentos} />,
                        },
                        {
                            path: 'criar',
                            element: <LoadComponent component={CriarDocumento} />,
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: 'editar',
                                    element: (
                                        <LoadComponent
                                            secondCheck={
                                                /* istanbul ignore next */ (u) =>
                                                    checkCan(u, AcessAction.UPDATE, AcessItem.DOCUMENT)
                                            }
                                            component={EditarDocumento}
                                        />
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'workflow',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={WorkflowList} />,
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: 'quadro',
                                    element: <LoadComponent component={QuadroWorkflow} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'modelos3d',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Modelos3D} />,
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: 'view',
                                    element: <LoadComponent component={ModelView} />,
                                },
                                {
                                    path: 'viewAll',
                                    element: <LoadComponent component={AllModelView} />,
                                },
                            ],
                        },
                        {
                            path: 'disciplinas',
                            element: <LoadComponent component={Disciplinas} />,
                        },
                    ],
                },
                {
                    path: 'sensores',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Sensores} />,
                        },
                        {
                            path: ':id',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={SensorData} />,
                                },
                                {
                                    path: 'editar',
                                    element: <LoadComponent component={SensorEdit} />,
                                },
                            ],
                        },
                        {
                            path: 'criar',
                            element: <LoadComponent component={SensorCreate} />,
                        },
                        // {
                        //     path: 'criar-multiplos',
                        //     element: <LoadComponent component={SensorMultiCreate} />,
                        // },
                    ],
                },

                // {
                //     path: 'dashboard',
                //     children: [
                //         {
                //             path: 'analytics',
                //             element: <LoadComponent component={AnalyticsDashboard} />,
                //         },
                //         {
                //             path: 'ativos',
                //             element: <LoadComponent component={Active} />,
                //         },
                //         {
                //             path: 'ecommerce',
                //             element: <LoadComponent component={EcommerceDashboard} />,
                //         },
                //         {
                //             path: 'project',
                //             element: <LoadComponent component={ProjectDashboard} />,
                //         },
                //         {
                //             path: 'e-wallet',
                //             element: <LoadComponent component={EWalletDashboard} />,
                //         },
                //     ],
                // },
                // {
                //     path: 'apps',
                //     children: [
                //         {
                //             path: 'calendar',
                //             element: <LoadComponent component={CalendarApp} />,
                //         },
                //         {
                //             path: 'chat',
                //             element: <LoadComponent component={ChatApp} />,
                //         },
                //         {
                //             path: 'crm',
                //             children: [
                //                 {
                //                     path: 'dashboard',
                //                     element: <LoadComponent component={CRMDashboard} />,
                //                 },
                //                 {
                //                     path: 'projects',
                //                     element: <LoadComponent component={CRMProjects} />,
                //                 },
                //                 {
                //                     path: 'management',
                //                     element: <LoadComponent component={CRMManagement} />,
                //                 },
                //                 {
                //                     path: 'clients',
                //                     element: <LoadComponent component={CRMClients} />,
                //                 },
                //                 {
                //                     path: 'orders',
                //                     element: <LoadComponent component={CRMOrderList} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'ecommerce',
                //             children: [
                //                 {
                //                     path: 'products',
                //                     element: <LoadComponent component={EcommerceProducts} />,
                //                 },
                //                 {
                //                     path: 'details',
                //                     element: <LoadComponent component={ProductDetails} />,
                //                 },
                //                 {
                //                     path: 'orders',
                //                     element: <LoadComponent component={Orders} />,
                //                 },
                //                 {
                //                     path: 'order/details',
                //                     element: <LoadComponent component={OrderDetails} />,
                //                 },
                //                 {
                //                     path: 'customers',
                //                     element: <LoadComponent component={Customers} />,
                //                 },
                //                 {
                //                     path: 'shopping-cart',
                //                     element: <LoadComponent component={Cart} />,
                //                 },
                //                 {
                //                     path: 'checkout',
                //                     element: <LoadComponent component={Checkout} />,
                //                 },
                //                 {
                //                     path: 'sellers',
                //                     element: <LoadComponent component={Sellers} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'email',
                //             children: [
                //                 {
                //                     path: 'inbox',
                //                     element: <LoadComponent component={Inbox} />,
                //                 },
                //                 {
                //                     path: 'details',
                //                     element: <LoadComponent component={EmailDetail} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'tasks',
                //             children: [
                //                 {
                //                     path: 'list',
                //                     element: <LoadComponent component={TaskList} />,
                //                 },
                //                 {
                //                     path: 'kanban',
                //                     element: <LoadComponent component={Kanban} />,
                //                 },
                //                 {
                //                     path: 'details',
                //                     element: <LoadComponent component={TaskDetails} />,
                //                 },
                //             ],
                //         },

                //         {
                //             path: 'projects',
                //             children: [
                //                 {
                //                     path: 'list',
                //                     element: <LoadComponent component={ProjectList} />,
                //                 },
                //                 {
                //                     path: 'details',
                //                     element: <LoadComponent component={ProjectDetail} />,
                //                 },
                //                 {
                //                     path: 'gantt',
                //                     element: <LoadComponent component={ProjectGannt} />,
                //                 },
                //                 {
                //                     path: 'new',
                //                     element: <LoadComponent component={ProjectForm} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'social',
                //             element: <LoadComponent component={SocialFeed} />,
                //         },
                //         {
                //             path: 'file',
                //             element: <LoadComponent component={FileManager} />,
                //         },
                //     ],
                // },
                // {
                //     path: 'pages',
                //     children: [
                //         {
                //             path: 'starter',
                //             element: <LoadComponent component={Starter} />,
                //         },
                //         {
                //             path: 'profile',
                //             element: <LoadComponent component={Profile} />,
                //         },
                //         {
                //             path: 'profile2',
                //             element: <LoadComponent component={Profile2} />,
                //         },
                //         {
                //             path: 'pricing',
                //             element: <LoadComponent component={Pricing} />,
                //         },
                //         {
                //             path: 'error-404-alt',
                //             element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                //         },
                //         {
                //             path: 'timeline',
                //             element: <LoadComponent component={Timeline} />,
                //         },
                //         {
                //             path: 'invoice',
                //             element: <LoadComponent component={Invoice} />,
                //         },
                //         {
                //             path: 'faq',
                //             element: <LoadComponent component={FAQ} />,
                //         },
                //         {
                //             path: 'preloader',
                //             element: <LoadComponent component={PreLoader} />,
                //         },
                //     ],
                // },
                // {
                //     path: 'ui',
                //     children: [
                //         {
                //             path: 'base-ui',
                //             children: [
                //                 {
                //                     path: 'accordions',
                //                     element: <LoadComponent component={Accordions} />,
                //                 },
                //                 {
                //                     path: 'alerts',
                //                     element: <LoadComponent component={Alerts} />,
                //                 },
                //                 {
                //                     path: 'avatars',
                //                     element: <LoadComponent component={Avatars} />,
                //                 },
                //                 {
                //                     path: 'badges',
                //                     element: <LoadComponent component={Badges} />,
                //                 },
                //                 {
                //                     path: 'breadcrumb',
                //                     element: <LoadComponent component={Breadcrumbs} />,
                //                 },
                //                 {
                //                     path: 'buttons',
                //                     element: <LoadComponent component={Buttons} />,
                //                 },
                //                 {
                //                     path: 'cards',
                //                     element: <LoadComponent component={Cards} />,
                //                 },
                //                 {
                //                     path: 'carousel',
                //                     element: <LoadComponent component={Carousels} />,
                //                 },
                //                 {
                //                     path: 'dropdowns',
                //                     element: <LoadComponent component={Dropdowns} />,
                //                 },
                //                 {
                //                     path: 'embedvideo',
                //                     element: <LoadComponent component={EmbedVideo} />,
                //                 },
                //                 {
                //                     path: 'grid',
                //                     element: <LoadComponent component={Grid} />,
                //                 },
                //                 {
                //                     path: 'listgroups',
                //                     element: <LoadComponent component={ListGroups} />,
                //                 },
                //                 {
                //                     path: 'modals',
                //                     element: <LoadComponent component={Modals} />,
                //                 },
                //                 {
                //                     path: 'notifications',
                //                     element: <LoadComponent component={Notifications} />,
                //                 },
                //                 {
                //                     path: 'offcanvas',
                //                     element: <LoadComponent component={Offcanvases} />,
                //                 },
                //                 {
                //                     path: 'placeholders',
                //                     element: <LoadComponent component={Placeholders} />,
                //                 },
                //                 {
                //                     path: 'paginations',
                //                     element: <LoadComponent component={Paginations} />,
                //                 },
                //                 {
                //                     path: 'popovers',
                //                     element: <LoadComponent component={Popovers} />,
                //                 },
                //                 {
                //                     path: 'progress',
                //                     element: <LoadComponent component={Progress} />,
                //                 },
                //                 {
                //                     path: 'ribbons',
                //                     element: <LoadComponent component={Ribbons} />,
                //                 },
                //                 {
                //                     path: 'spinners',
                //                     element: <LoadComponent component={Spinners} />,
                //                 },
                //                 {
                //                     path: 'tabs',
                //                     element: <LoadComponent component={Tabs} />,
                //                 },
                //                 {
                //                     path: 'tooltips',
                //                     element: <LoadComponent component={Tooltips} />,
                //                 },
                //                 {
                //                     path: 'typography',
                //                     element: <LoadComponent component={Typography} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'widgets',
                //             element: <LoadComponent component={Widgets} />,
                //         },
                //         {
                //             path: 'extended',
                //             children: [
                //                 {
                //                     path: 'dragdrop',
                //                     element: <LoadComponent component={DragDrop} />,
                //                 },
                //                 {
                //                     path: 'rangesliders',
                //                     element: <LoadComponent component={RangeSliders} />,
                //                 },
                //                 {
                //                     path: 'ratings',
                //                     element: <LoadComponent component={Ratings} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'icons',
                //             children: [
                //                 {
                //                     path: 'unicons',
                //                     element: <LoadComponent component={Unicons} />,
                //                 },
                //                 {
                //                     path: 'mdi',
                //                     element: <LoadComponent component={MDIIcons} />,
                //                 },
                //                 {
                //                     path: 'dripicons',
                //                     element: <LoadComponent component={Dripicons} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'forms',
                //             children: [
                //                 {
                //                     path: 'basic',
                //                     element: <LoadComponent component={BasicForms} />,
                //                 },
                //                 {
                //                     path: 'advanced',
                //                     element: <LoadComponent component={FormAdvanced} />,
                //                 },
                //                 {
                //                     path: 'validation',
                //                     element: <LoadComponent component={FormValidation} />,
                //                 },
                //                 {
                //                     path: 'wizard',
                //                     element: <LoadComponent component={FormWizard} />,
                //                 },
                //                 {
                //                     path: 'upload',
                //                     element: <LoadComponent component={FileUpload} />,
                //                 },
                //                 {
                //                     path: 'editors',
                //                     element: <LoadComponent component={Editors} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'tables',
                //             children: [
                //                 {
                //                     path: 'basic',
                //                     element: <LoadComponent component={BasicTables} />,
                //                 },
                //                 {
                //                     path: 'advanced',
                //                     element: <LoadComponent component={AdvancedTables} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'charts',
                //             children: [
                //                 {
                //                     path: 'apex',
                //                     element: <LoadComponent component={ApexChart} />,
                //                 },
                //                 {
                //                     path: 'chartjs',
                //                     element: <LoadComponent component={ChartJs} />,
                //                 },
                //             ],
                //         },
                //         {
                //             path: 'maps',
                //             children: [
                //                 {
                //                     path: 'googlemaps',
                //                     element: <LoadComponent component={GoogleMaps} />,
                //                 },
                //                 {
                //                     path: 'vectormaps',
                //                     element: <LoadComponent component={VectorMaps} />,
                //                 },
                //             ],
                //         },
                //     ],
                // },
            ],
        },
        { path: '*', element: <LoadComponent component={Error404} /> },
    ]);
};

export { AllRoutes };
