import * as yup from 'yup';

interface Check {
    regex: RegExp;
    errorMessage: string;
}

export const customMatchs = (checks: Check[], field: string) => (value?: string) => {
    const errors: string[] = [];
    if (value) {
        checks.forEach(({ errorMessage, regex }) => !regex.test(value) && errors.push(errorMessage));
        if (errors.length) return new yup.ValidationError(errors.join('\n'), value, field);
    }
    return true;
};
